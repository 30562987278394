import { withNavigationWatcher } from "./contexts/navigation";
import {
  WelcomePage,
  FarmersPage,
  FarmerPage,
  CohortsPage,
  CohortPage,
  PointsOfSalePage,
  PricesPage,
  Logout,
  ReportingDashboard,
  Session,
  AttendanceList
} from "./pages"


export const getProgramPath = (program) => `/${program.toLowerCase()}`
export const getLoginPath = (program) => `${getProgramPath(program)}/login`
export const getForgotPasswordPath = (program) => `${getProgramPath(program)}/forgot-password`
export const getResetPasswordPath = (program) => `${getProgramPath(program)}/reset-password`


export const getWelcomePath = (program) => `${getProgramPath(program)}/welcome`
export const getFarmersPath = (program) => `${getProgramPath(program)}/farmers`
export const getFarmerCreatePath = (program) => `${getProgramPath(program)}/create-farmer/create`
export const getFarmerEditPath = (program, id) =>  `${getProgramPath(program)}/edit-farmer/edit/${id || ":id"}`
export const getCohortsPath = (program) => `${getProgramPath(program)}/cohorts`
export const getCohortCreatePath = (program) => `${getProgramPath(program)}/create-cohort/create`
export const getCohortEditPath = (program, id) => `${getProgramPath(program)}/edit-cohort/edit/${id || ":id"}`
export const getPointsOfSalePath = (program) => `${getProgramPath(program)}/getPointsOfSalePath`
export const getPointOfSaleEditPath = (program, id) => `${getProgramPath(program)}/pointsOfSale/${id || ":id"}`
export const getLogoutPath = (program) => `${getProgramPath(program)}/logout`
export const getCreateSessionPath = (program, course, cohort) => `${getProgramPath(program)}/session/new/${course || ":course"}/${cohort || ":cohortId"}`
export const getEditSessionPath = (program, id, cohortId) => `${getProgramPath(program)}/session/edit/${id || ":id"}/${cohortId || ":cohortId"}`
export const getAttendanceListPath = (program, sessionId, cohortId) => `${getProgramPath(program)}/attendance/${cohortId || ":cohortId"}/${sessionId || ":sessionId"}`
export const getReportPath = (program, report) => `${getProgramPath(program)}/report/${report || ":report"}`



//This page create all the path of the application like access to different cohort or farmer profile
const routes = [
  {
    path: getWelcomePath,
    component: WelcomePage,
  },
  {
    path: getFarmersPath,
    component: FarmersPage,
  },
  {
    path: getFarmerCreatePath,
    component: FarmerPage,
  },
  {
    path: getFarmerEditPath,
    component: FarmerPage,
  },
  {
    path: getCohortsPath,
    component: CohortsPage,
  },
  {
    path: getCohortCreatePath,
    component: CohortPage
  },
  {
    path: getCohortEditPath,
    component: CohortPage
  },
  {
    path: getPointsOfSalePath,
    component: PointsOfSalePage,
  },
  {
    path: getPointOfSaleEditPath,
    component: PricesPage
  },
  {
    path: getLogoutPath,
    component: Logout
  },
  {
    path: getReportPath,
    component: ReportingDashboard
  },
  {
    path: getCreateSessionPath,
    component : Session
  },
  {
    path: getAttendanceListPath,
    component : AttendanceList
  },
  {
    path: getEditSessionPath,
    component : Session
  },
  {
    path: getReportPath,
    component : ReportingDashboard
  }
];

export const appRoutes = routes.map((route) => ({
  ...route,
  component: withNavigationWatcher(route.component)
}))
