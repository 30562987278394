import { getService } from './api'
const baseUrl = process.env.REACT_APP_PARTICIPANTSUITE_BACKEND_URL;
export async function getMetabaseSignedUrl(token, metabaseSlug) {

    const url = `${baseUrl}/bo/reporter?slug=${metabaseSlug}`;
    // Call to the api to get the signed url
    const res = await getService(token, url);

    // Return url
    return res.data
}
