import farmerIcon from "./resources/icons/farmer.svg"
import cohortIcon from "./resources/icons/cohort.svg"
import scheduleIcon from "./resources/icons/schedule.svg";
import DeployIcon from "./resources/icons/deploy.svg";
import BuyersIcon from "./resources/icons/buyers.svg";
import ReportsIcon from "./resources/icons/report.svg";
import FarmersMonitoring from "./resources/icons/farmersMonitoring.svg";
import CohortsMonitoring from "./resources/icons/cohortsMonitoring.svg";
import AnomaliesIcon from "./resources/icons/lookforanomalies.svg";
import PointsOfSaleIcon from "./resources/icons/shop.svg";
import { toTitleCase } from "./utils/utils";
import { getReportPath, getCohortsPath, getFarmersPath, getPointsOfSalePath, getProgramPath } from './app-routes'

export const navigation = [
  {
    text: "Participants",
    path: (p) => getFarmersPath(p),
    icon: farmerIcon,
    roles: ['INSTRUCTOR']
  },
  {
    text: "Cohorts",
    path: (p) => getCohortsPath(p),
    icon: cohortIcon,
    roles: ['INSTRUCTOR']
  },
  {
    text: "Schedule",
    path: (p) => `${getProgramPath(p)}/schedule`,
    icon: scheduleIcon,
    roles: ['INSTRUCTOR']
  },
  {
    text: "Deploy",
    path: (p) => `${getProgramPath(p)}/deploy`,
    icon: DeployIcon,
    roles: ['INSTRUCTOR']
  },
  {
    text: "Buyers",
    path: (p) => `${getProgramPath(p)}/buyers`,
    icon: BuyersIcon,
    roles: ['INSTRUCTOR']
  },
  {
    text: "Reports",
    path: (p) =>  `${getReportPath(p, "program-reports-for-delivery-partners")}`,
    icon: ReportsIcon,
    roles: ['INSTRUCTOR']
  },
  {
    text: "Participants monitoring",
    path: (p) => `${getProgramPath(p)}/farmers-monitoring`,
    icon: FarmersMonitoring,
    roles: ['INSTRUCTOR']
  },
  {
    text: "Cohorts monitoring",
    path: (p) => `${getProgramPath(p)}/cohorts-monitoring`,
    icon: CohortsMonitoring,
    roles: ['INSTRUCTOR']
  },
  {
    text: "Checking anomalies",
    path: (p) => `${getProgramPath(p)}/cheking`,
    icon: AnomaliesIcon,
    roles: ['INSTRUCTOR']
  },{
    text: "Points of sale",
    path: (p) => getPointsOfSalePath(p),
    icon: PointsOfSaleIcon,
    roles: ['LOCAL_BUYER']
  }
];

export const getNavByUserRoles = (userRoles, dashboards_queries) => {
  let nav = navigation.filter(navItem => navItem.roles.filter(role => userRoles.includes(role)).length > 0)
  // If user is reporter and has dashboard in his program configs, retrieve Metabase slug dashboards
  if(dashboards_queries !== undefined && userRoles.includes('REPORTER')) {
    // new URLs are created in app-routes
    dashboards_queries.map((query) => {
      return nav.push({
        text: toTitleCase(query.replaceAll("-", " ")),
        path: (p) => getReportPath(p, query),
        icon: ReportsIcon,
        roles: ['REPORTER']  
      })})
  }
  return nav
}
