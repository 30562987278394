import React, { useMemo } from 'react'
import { useLogin } from "../../cookies";
import { getNavByUserRoles } from "../../app-navigation";
import { Link } from 'react-router-dom'
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import packageJson from "../../../package.json";
import './welcome.scss';
import { useProgram } from '../../programs'

function screen(width) {
  return (width < 700) ? 'sm' : 'lg';
}

const Welcome = () => {
  const program = useProgram()
  const { user } = useLogin();


  const filteredNav = useMemo(() => getNavByUserRoles(user.userRoles, program.configs?.dashboards), [user, program]);

    return (
        <React.Fragment>
          <div className="dx-viewport">
            <div className="containerWelcome">
              <ResponsiveBox singleColumnScreen="sm" screenByWidth={screen}>
                <Row ratio={1}></Row>
                <Row ratio={2} screen="xs"></Row>
                <Row ratio={2}></Row>
                <Row ratio={1}></Row>

                <Col ratio={1}></Col>
                <Col ratio={2} screen="lg"></Col>
                <Col ratio={1}></Col>
                  <Item>
                    <Location row={0} col={0} colspan={3} screen="lg"></Location>
                    <Location row={0} col={0} colspan={2} screen="sm"></Location>
                    <div className="header item">
                      <h1>Hello {`${user.firstName}`}</h1>
                      <p className="paragraphe">What do you want to do today ?</p>
                    </div>
                  </Item>
                  <Item>
                    <Location row={1} col={1} screen="lg"></Location>
                    <Location row={1} col={0} colspan={2} screen="sm"></Location>
                      <div className="contentIcon item">
                        <div className="grid">
                          { filteredNav.map((link, index) => {
                            const { icon, text, path } = link;
                            return (
                              <Link to={path(program.name)} key={index}>
                                <div className="rectangle">
                                  <img src={icon} alt={text}/>
                                    <br />
                                    {text}
                                </div>
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                  </Item>
                  <Item>
                    <Location row={2} col={0} colspan={3} screen="lg"></Location>
                    <Location row={2} col={0} colspan={2} screen="sm"></Location>
                    <div className="footer item">
                      <p>SUTTI™ v{packageJson.version}. Copyright © KSAPA 2019-{new Date().getFullYear()}. All rights reserved.</p>
                    </div>
                  </Item>
                </ResponsiveBox>
              </div>
            </div>
        </React.Fragment>
    )
}
export default Welcome;
