import React, { useEffect, useMemo } from 'react'
import { NumberBox, TextBox, Validator } from "devextreme-react";
import Form, { GroupItem, Label, RequiredRule, NumericRule, SimpleItem, PatternRule } from "devextreme-react/form";
import { Tooltip } from "devextreme-react/tooltip";
import notify from "devextreme/ui/notify";
import PropTypes from "prop-types";

import { createFarmData, fetchFarmDataById, updateFarmData } from "../../api/farmers";
import { Button, FileUploader, MapLayer, Spinner } from "../../components";
import { useLogin } from "../../cookies";
import { processNullValues, toBase64 } from "../../utils/utils";
import { getBaseEditorOptions } from "./bioData";
import { useProgram } from '../../programs'
import "./farmer.scss";


const marketRoutes = ["Local Dealer", "Cooperative", "Factory"];
// const accidentOptions = [
//   "Eye infection / red eye",
//   "Skin rash",
//   "Cough",
//   "Cut / Wound",
//   "Animal bites (snakes…)",
//   "Road accident during work activities",
//   "Falls, hits in plantation",
//   "Pain in the back, painful arm/hand…",
// ];

const validationRules = {
  farmer: [{ type: "required", message: "Farmer is required" }],
  totalFarmedSurface: [
    { type: "required", message: "Total Farmed Surfacermer is required" },
  ],
  totalUnfarmedSurface: [
    { type: "required", message: "Total Unfarmed Surface is required" },
  ],
  mainProgramCrop: [
    { type: "required", message: "Main Program Crop is required" },
  ],
  numberOfEmployees: [
    { type: "required", message: "Number Of Employees is required" },
    { type: "numeric", message: "The value should be a number." }
  ]
};

const FarmData = ({ isEditing, onSubmit, farmerId }) => {
  const program = useProgram();
  const { token } = useLogin();

  
  // Set the surface unit depending the program configs
  const renderUnit = (unit) => {
    if (unit === "ac") {
      return "Acres"
    } else if (unit === "ha") {
      return "Hectares"
    }
  }


  const initialFarmDataValues = useMemo(() => ({
    farmer: null,
    gpsPoint: null,
    totalFarmedSurface: null,
    totalUnfarmedSurface: null,
    mainCultivatedCrop: program?.configs?.mainCommodity,
    mainProgramCrop: null,
    numberOfEmployees: null,
    workersAverageAge: null,
    youngestWorkerAge: null,
    preferredGoToMarketRoute: null,
    distanceToPointOfSale: null,
    mainCropPlots: [
      {
        nickname: null,
        size: null,
        yearOfPlanting: null,
        harvestingStartYear: null,
        density: null,
        variety: null,
        cloneName: null,
        geoShape: null,
        proofOfOwnership: null,
        registrationNumber: null,
        proofsOfRegistrationNumberFile: null
      },
    ],
    numberOfCattleHeads: null,
    averageWeeklyHoursSpentOnCattle: null,
    numberOfFishHeads: null,
    averageWeeklyHoursSpentOnFish: null,
    numberOfProducts: null,
    averageWeeklyHoursSpentOnCommerce: null,
    numberOfWorkAccidentsPerYear: null,
    numberOfWorkDaysLostDueToWorkAccidentsPerYear: null,
    unit : renderUnit(program?.configs?.unit),
  }), [program]);

  const [loading, setLoading] = React.useState(false);
  const [hasFarmData, setHasFarmData] = React.useState(false);
  const [farmDataValues, setFarmDataValues] = React.useState(initialFarmDataValues);
  const isMounted = React.useRef(false);

  const fetchFarmData = React.useCallback(async () => {
    const fetchedFarmData = await fetchFarmDataById(token, farmerId);

    if (fetchedFarmData.isOk) {
      setFarmDataValues(fetchedFarmData.data);
      setHasFarmData(true);
    } else {
      setFarmDataValues({ ...initialFarmDataValues, farmer: farmerId });
    }
  }, [initialFarmDataValues, farmerId, token]);

  useEffect(() => {
    if (isMounted.current === false) {
      fetchFarmData();
      isMounted.current = true;
    }
  }, [fetchFarmData]);

  const renderRouteOptions = useMemo(
    () => ({
      items: marketRoutes,
      placeholder: "Select preferred Go-To-Market route",
      showClearButton: true, ...getBaseEditorOptions(!isEditing)
    }),
    [isEditing]
  );

  // const renderAccidentOptions = React.useMemo(
  //   () => ({ items: accidentOptions, ...getBaseEditorOptions(!isEditing) }),
  //   [isEditing]
  // );

  const handleAddPlot = () => {
    const updatedValues = {
      ...farmDataValues,
      mainCropPlots: farmDataValues.mainCropPlots.concat({
        nickname: null,
        size: null,
        yearOfPlanting: null,
        harvestingStartYear: null,
        density: null,
        variety: null,
        cloneName: null,
        geoShape: null,
        proofOfOwnership: null,
        registrationNumber: null,
        proofsOfRegistrationNumberFile: null
      }),
    };
    setFarmDataValues(updatedValues);
  };

  const handleRemovePlot = (indexToRemove) => {
    const updatedValues = {
      ...farmDataValues,
      mainCropPlots: farmDataValues.mainCropPlots.filter((plot, index) => {
        return index !== indexToRemove;
      }),
    };
    setFarmDataValues(updatedValues);
  };


  const handleAddGeoShape = (mainCropPlot) => {
    mainCropPlot.geoShape = {
      type: "Polygon",
      coordinates: [[
        [], []
      ]]
    }
    setFarmDataValues({...farmDataValues})
  }

  const handleCoordinateChange = (coordinates, pointIndex, lngLatIndex, value) => {
    const numberValue = parseFloat(value)
    coordinates[pointIndex][lngLatIndex] = numberValue
    if(pointIndex === 0) coordinates[coordinates.length-1][lngLatIndex] = numberValue
    setFarmDataValues({...farmDataValues})
  }

  const handleAddPoint = (coordinates, pointIndex) => {
    coordinates.splice(pointIndex, 0, [])
    setFarmDataValues({...farmDataValues})
  };

  const handleRemovePoint = (mainCropPlot, coordinates, pointIndex) => {
    if(coordinates.length === 2){
      mainCropPlot.geoShape = null
    } else {
      coordinates.splice(pointIndex, 1)
      if(pointIndex === 0) coordinates[coordinates.length-1] = coordinates[0]
    }
    setFarmDataValues({...farmDataValues})
  };


  const mapCenter = useMemo(
    () => {
      if (farmDataValues.gpsPoint) {
        return  {lat: farmDataValues.gpsPoint.coordinates[1], lng: farmDataValues.gpsPoint.coordinates[0]}
      } else {
        return {lat: 0, lng: 0}
      }
    },[farmDataValues.gpsPoint]);

    const handleCoordinatesChange = (value, key) => {
      let coordinates;
        const gps = farmDataValues.gpsPoint.coordinates
        if (key === 'long'){
          coordinates = {
            type: "Point",
            coordinates : [parseFloat(value), gps[1]]
          }
        }else if(key === 'lat') {
          coordinates = {
            type: "Point",
            coordinates : [ gps[0], parseFloat(value)]
          }
        }
        else return
        const updatedValues = {
          ...farmDataValues,
          gpsPoint: coordinates
        };
        setFarmDataValues(updatedValues);
    }
  const handleMapCenter = (e) => {
    if (isEditing) {
      const lat = e.latLng.lat()
      const lng = e.latLng.lng()
      const updatedValues = {
        ...farmDataValues,
        gpsPoint: {
          "type": "Point",
          "coordinates": [lng, lat]
        }
      };
      setFarmDataValues(updatedValues);
    }
  };

  const polygons = useMemo(
    () => farmDataValues.mainCropPlots && farmDataValues.mainCropPlots.map(mainCropPlot => {
      const nickname = mainCropPlot.nickname
      const markers = mainCropPlot.geoShape?.coordinates[0]
      const plotMarkers = markers && markers.map(marker => (
        // GeoJSON coordinates are in (lng, lat) order
        { lat:marker[1], lng:marker[0] }
      ));
      return ({
        nickname, plotMarkers
      });
    }),[farmDataValues]);

  const handleFileChange = async (data, key, file) => {
    data[key] = file && await toBase64(file)
    setFarmDataValues({...farmDataValues});
  }

  const handleSubmit = async () => {
    setLoading(true);
    if (hasFarmData) {
      const valuesToSend = processNullValues(farmDataValues);
      const res = await updateFarmData(token, valuesToSend);
      if (res.isOk) {
        notify("Farm data was successfully updated", "success", 2000);
        await fetchFarmData();
        onSubmit();
      } else {
        if(res.message.includes(403)){
          notify("Registration Number may be already in use", "error", 2000);
        }else{
          notify(res.message, "error", 2000);
        }
      }
    } else {
      const res = await createFarmData(token, farmDataValues);
      if (res.isOk) {
        notify("Farm data was successfully created", "success", 2000);
        await fetchFarmData();
        onSubmit();
      } else {
        notify(res.message, "error", 2000);
      }
    }
    setLoading(false);
  };

  const validateGroup = async (e) => {
    const res = await e.validationGroup.validate();
    if (res.brokenRules.length > 0) {
      notify("Please fill in all required details", "error", 4000);
    } else {
      await handleSubmit();
    }
  };
  return (
  !farmDataValues.farmer ? 
  <Spinner />

  : 
    <>
      <Form formData={farmDataValues} validationGroup={"farmDataGroup"}>
        <GroupItem cssClass="first-group" caption="Farm site details">
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="farmer"
              editorOptions={{
                disabled: true,
                stylingMode: "outlined",
              }}
              validationRules={validationRules.farmer}
            />
          </GroupItem>
          <GroupItem colCount={3}>
            <SimpleItem
              dataField={`totalFarmedSurfaceIn${initialFarmDataValues.unit}`}
              validationRules={validationRules.totalFarmedSurface}
              render={() => (
                <div>
                  <NumberBox
                    id="totalFarmedSurface"
                    min={0}
                    value={farmDataValues.totalFarmedSurface}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        totalFarmedSurface: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"farmDataGroup"}>
                      <RequiredRule
                        message={"Total Farmed Surface is required."}
                      />
                    </Validator>
                  </NumberBox>
                  <Tooltip
                    target="#totalFarmedSurface"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Total Farmed Surface In Farm in {initialFarmDataValues.unit} </div>
                  </Tooltip>
                </div>
              )}
            />
            <SimpleItem
              dataField={`totalUnfarmedSurfaceIn${initialFarmDataValues.unit}`}
              validationRules={validationRules.totalUnfarmedSurface}
              render={() => (
                <div>
                  <NumberBox
                    id="totalUnfarmedSurface"
                    min={0}
                    value={farmDataValues.totalUnfarmedSurface}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        totalUnfarmedSurface: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"farmDataGroup"}>
                      <RequiredRule
                        message={"Total Unfarmed Surface is required."}
                      />
                    </Validator>
                  </NumberBox>
                  <Tooltip
                    target="#totalUnfarmedSurface"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Total Unfarmed Surface {initialFarmDataValues.unit} </div>
                  </Tooltip>
                </div>
              )}
            />
            <SimpleItem
              dataField="mainCultivatedCrop"
              render={() => (
                <div>
                  <TextBox
                    id="mainCultivatedCrop"
                    value={farmDataValues.mainCultivatedCrop}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        mainCultivatedCrop: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  ></TextBox>
                  <Tooltip
                    target="#mainCultivatedCrop"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Main Cultivated Crop</div>
                  </Tooltip>
                </div>
              )}
            />
          </GroupItem>
          <GroupItem colCount={3}>
            <SimpleItem
              dataField="numberOfEmployees"
              validationRules={validationRules.numberOfEmployees}
              render={() => (
                <div>
                  <NumberBox
                    id="numberOfEmployees"
                    min={0}
                    value={farmDataValues.numberOfEmployees}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        numberOfEmployees: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"farmDataGroup"}>
                      <RequiredRule
                        message={"Number Of Employees is required."}
                      />
                      <PatternRule
                        pattern={/\b(?<!\.)\d+(?!\.)\b/}
                        message="Value should be integer number"
                      />
                    </Validator>
                  </NumberBox>
                  <Tooltip
                    target="#numberOfEmployees"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Number Of Employees</div>
                  </Tooltip>
                </div>
              )}
            />
            <SimpleItem
              dataField="workersAverageAge"
              render={() => (
                <div>
                  <NumberBox
                    id="workersAverageAge"
                    min={0}
                    value={farmDataValues.workersAverageAge}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        workersAverageAge: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                  </NumberBox>
                  <Tooltip
                    target="#workersAverageAge"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Workers Average Age</div>
                  </Tooltip>
                </div>
              )}
            />
            <SimpleItem
              dataField="youngestsWorkerAge"
              render={() => (
                <div>
                  <NumberBox
                    id="youngestsWorkerAge"
                    min={0}
                    value={farmDataValues.youngestsWorkerAge}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        youngestsWorkerAge: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"farmDataGroup"}>
                      <PatternRule
                        pattern={/\b(?<!\.)\d+(?!\.)\b/}
                        message="Value should be integer number"
                      />
                    </Validator>
                  </NumberBox>
                  <Tooltip
                    target="#youngestsWorkerAge"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Youngest Worker Age</div>
                  </Tooltip>
                </div>
              )}
            />
          </GroupItem>
          <GroupItem colCount={2}>
          <SimpleItem
            dataField="longitude"
            render={() => (
              <div>
                <NumberBox
                  id="longitude"
                  min={0}
                  value={farmDataValues.gpsPoint?.coordinates[0]}
                  onValueChange={(value) =>handleCoordinatesChange(value, 'long')}
                  stylingMode="outlined"
                  disabled={!isEditing}
                >
                </NumberBox>
                <Tooltip
                  target="#longitude"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  closeOnOutsideClick={false}
                >
                  <div>GPS Coordinates (longitude)</div>
                </Tooltip>
              </div>
            )}
          />
          <SimpleItem
            dataField="latitude"
            render={() => (
              <div>
                <NumberBox
                  id="latitude"
                  min={0}
                  value={farmDataValues.gpsPoint?.coordinates[1]}
                  onValueChange={(value) =>handleCoordinatesChange(value, 'lat')}
                  stylingMode="outlined"
                  disabled={!isEditing}
                >
                </NumberBox>
                <Tooltip
                  target="#latitude"
                  showEvent="dxhoverstart"
                  hideEvent="dxhoverend"
                  closeOnOutsideClick={false}
                >
                  <div>GPS Coordinates (latitude)</div>
                </Tooltip>
              </div>
            )}
          />
          </GroupItem>
          <SimpleItem
            dataField="map"
            render={() => (
              <MapLayer
                center={mapCenter}
                onClick={handleMapCenter}
                polygons={polygons}
              />
            )}
          />
        </GroupItem>
        <GroupItem cssClass="second-group" caption="Main crop details">
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="preferredGoToMarketRoute"
              editorType="dxSelectBox"
              editorOptions={renderRouteOptions}
            />
            <SimpleItem
              dataField="distanceToPointOfSale"
              render={() => (
                <div>
                  <NumberBox
                    id="distanceToPointOfSale"
                    min={0}
                    value={farmDataValues.distanceToPointOfSale}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        distanceToPointOfSale: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                  </NumberBox>
                  <Tooltip
                    target="#distanceToPointOfSale"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Distance To Point Of Sale</div>
                  </Tooltip>
                </div>
              )}
            />
          </GroupItem>
          <GroupItem name={"mainCropPlots"}>
            {farmDataValues.mainCropPlots.map((data, index) => (
              <GroupItem key={index}>
                <SimpleItem
                  key={`mainCropPlots${index}.nickname`}
                  dataField={`mainCropPlots[${index}].nickname`}
                  editorOptions={{
                    value: data.nickname,
                    placeholder:"indicate the plot nickname",
                    ...getBaseEditorOptions(!isEditing),
                  }}
                >
                  <Label text={`plot ${index + 1} nickname`} />
                </SimpleItem>
                <GroupItem colCount={3}>
                  <SimpleItem
                    key={`mainCropPlots${index}.size`}
                    dataField={`mainCropPlots[${index}].size`}
                    render={() => (
                      <div>
                        <NumberBox
                        min={0}
                        placeholder="indicate the plot size"
                        id={`mainCropPlots${index}.size`}
                        value={farmDataValues.mainCropPlots[index].size}
                        onValueChange={(value) =>
                          setFarmDataValues((prevState) => {
                            const mainCropPlots = [...prevState.mainCropPlots];
                            mainCropPlots[index].size = value;
                            return {
                              ...prevState,
                              mainCropPlots: mainCropPlots,
                            };
                          })
                        }
                        stylingMode="outlined"
                        disabled={!isEditing}
                      >
                      <Validator validationGroup={"farmDataGroup"}>
                      <RequiredRule
                        message={"Crop plot size is required."}
                      />
                    </Validator>
                      </NumberBox>
                      </div>
                    )}

                  >
                    <Label text={`plot ${index + 1} size`} />
                  </SimpleItem>
                  <SimpleItem
                    key={`mainCropPlots${index}.yearOfPlanting`}
                    dataField={`mainCropPlots[${index}].yearOfPlanting`}
                    render={() => (
                      <div>
                        <NumberBox
                        min={0}
                        placeholder="indicate the year of planting"
                        id={`mainCropPlots${index}.yearOfPlanting`}
                        value={farmDataValues.mainCropPlots[index].yearOfPlanting}
                        onValueChange={(value) =>
                          setFarmDataValues((prevState) => {
                            const mainCropPlots = [...prevState.mainCropPlots];
                            mainCropPlots[index].yearOfPlanting = value;
                            return {
                              ...prevState,
                              mainCropPlots: mainCropPlots,
                            };
                          })
                        }
                        stylingMode="outlined"
                        disabled={!isEditing}
                      >
                      <Validator validationGroup={"farmDataGroup"}>
                      <PatternRule
                        pattern={/\b(?<!\.)\d+(?!\.)\b/}
                        message="Value should be integer number"
                      />
                    </Validator>  
                      </NumberBox>
                      </div>
                    )}
                  >
                    <Label text={`plot ${index + 1} year of planting`} />
                  </SimpleItem>
                  <SimpleItem
                    key={`mainCropPlots${index}.harvestingStartYear`}
                    dataField={`mainCropPlots[${index}].harvestingStartYear`}
                    render={() => (
                      <div>
                        <NumberBox
                        min={0}
                        placeholder="indicate the harvesting start year"
                        id={`mainCropPlots${index}.harvestingStartYear`}
                        value={farmDataValues.mainCropPlots[index].harvestingStartYear}
                        onValueChange={(value) =>
                          setFarmDataValues((prevState) => {
                            const mainCropPlots = [...prevState.mainCropPlots];
                            mainCropPlots[index].harvestingStartYear = value;
                            return {
                              ...prevState,
                              mainCropPlots: mainCropPlots,
                            };
                          })
                        }
                        stylingMode="outlined"
                        disabled={!isEditing}
                      >
                      <Validator validationGroup={"farmDataGroup"}>
                      <PatternRule
                        pattern={/\b(?<!\.)\d+(?!\.)\b/}
                        message="Value should be integer number"
                      />
                    </Validator>
                      </NumberBox>
                      </div>
                    )}
                  >
                    <Label text={`plot ${index + 1} harvesting start year`} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem colCount={3}>
                  <SimpleItem
                    key={`mainCropPlots${index}.density`}
                    dataField={`mainCropPlots[${index}].density`}
                    render={() => (
                      <div>
                        <NumberBox
                        min={0}
                        placeholder="indicate the plot density"
                        id={`mainCropPlots${index}.density`}
                        value={farmDataValues.mainCropPlots[index].density}
                        onValueChange={(value) =>
                          setFarmDataValues((prevState) => {
                            const mainCropPlots = [...prevState.mainCropPlots];
                            mainCropPlots[index].density = value;
                            return {
                              ...prevState,
                              mainCropPlots: mainCropPlots,
                            };
                          })
                        }
                        stylingMode="outlined"
                        disabled={!isEditing}
                      >
                      </NumberBox>
                      </div>
                    )}
                  >
                    <Label text={`plot ${index + 1} density`} />
                  </SimpleItem>
                  <SimpleItem
                    key={`mainCropPlots${index}.variety`}
                    dataField={`mainCropPlots[${index}].variety`}
                    editorOptions={{
                      value: data.variety,
                      placeholder:"indicate the variety of the crop plot",
                      ...getBaseEditorOptions(!isEditing),
                    }}
                  >
                    <Label text={`plot ${index + 1} variety`} />
                  </SimpleItem>

                  <SimpleItem
                    key={`mainCropPlots${index}.cloneName`}
                    dataField={`mainCropPlots[${index}].cloneName`}
                    editorOptions={{
                      value: data.cloneName,
                      placeholder:"indicate the clone name",
                      ...getBaseEditorOptions(!isEditing),
                    }}
                  >
                    <Label text={`plot ${index + 1} clone name`} />
                  </SimpleItem>
                </GroupItem>
                <GroupItem colCount={3} >
                  <Label text={`plot ${index + 1} geographic coordinates.`} />
                  {data.geoShape ?
                    data.geoShape.coordinates[0].slice(0, -1).map((point, pointIndex) => (
                    <GroupItem colCount={1}>
                      <SimpleItem
                        dataField={`mainCropPlots[${index}].geoShape.coordinates[0][${pointIndex}][1]`}
                        render={() => (
                          <TextBox
                            value={point[1]}
                            onValueChange={(value) => handleCoordinateChange(data.geoShape?.coordinates[0], pointIndex, 1, value)}
                            stylingMode="outlined"
                            disabled={!isEditing}
                          >
                            <Validator validationGroup={"farmDataGroup"}>
                              <NumericRule />
                            </Validator>
                          </TextBox>
                        )}
                      >
                        <Label text={`point ${pointIndex +1} : latitude`} />
                      </SimpleItem>
                      <SimpleItem
                        dataField={`mainCropPlots[${index}].geoShape.coordinates[0][${pointIndex}][0]`}
                        render={() => (
                          <TextBox
                            value={point[0]}
                            onValueChange={(value) => handleCoordinateChange(data.geoShape?.coordinates[0], pointIndex, 0, value)}
                            stylingMode="outlined"
                            disabled={!isEditing}
                          >
                            <Validator validationGroup={"farmDataGroup"}>
                              <NumericRule />
                            </Validator>
                          </TextBox>
                        )}
                      >
                        <Label text={`point ${pointIndex +1} : longitude`} />
                      </SimpleItem>
                      <GroupItem>
                        <SimpleItem
                          render={() => (
                            <Button
                              text={"Add point after"}
                              onClick={() => handleAddPoint(data.geoShape?.coordinates[0], pointIndex+1)}
                              disabled={!isEditing}
                            />
                          )}
                        />
                        <SimpleItem
                          render={() => (
                            <Button
                              text={"Remove point"}
                              onClick={() => handleRemovePoint(data, data.geoShape?.coordinates[0], pointIndex)}
                              disabled={!isEditing}
                            />
                          )}
                        />
                      </GroupItem>
                    </GroupItem>
                  )) : (
                    <GroupItem colCount={1}>
                      <SimpleItem
                        render={() => (
                          <Button
                            text={"Add geographic coordinates"}
                            onClick={() => handleAddGeoShape(data)}
                            disabled={!isEditing}
                          />
                        )}
                      />
                     </GroupItem>
                  )}
                </GroupItem>

                <SimpleItem dataField={`mainCropPlots[${index}].proofOfOwnership`}>
                  <FileUploader
                    file={data.proofOfOwnership}
                    label="proof of ownership"
                    isEditable={isEditing}
                    onFileChange={(value) => handleFileChange(data, "proofOfOwnership", value)}
                  />
                  <Label text={`plot ${index + 1} proof of ownership`} />
                </SimpleItem>
                <GroupItem colCount={2}>
                <SimpleItem
                  key={`mainCropPlots${index}.registrationNumber`}
                  dataField={`mainCropPlots[${index}].registrationNumber`}
                  editorOptions={{
                    value: data.registrationNumber,
                    placeholder:"indicate the plot registration Number",
                    ...getBaseEditorOptions(!isEditing),
                  }}
                >
                  <Label text={`plot ${index + 1} Registration Number`} />
                </SimpleItem>
                <SimpleItem dataField={`mainCropPlots[${index}].proofsOfRegistrationNumberFile`}>
                  <FileUploader
                    file={data.proofsOfRegistrationNumberFile}
                    label="Proof of Registration Number"
                    isEditable={isEditing}
                    onFileChange={(value) => handleFileChange(data, "proofsOfRegistrationNumberFile", value)}
                  />
                  <Label text={`plot ${index + 1} proof of registration number`} />
                </SimpleItem>
                </GroupItem>
                <GroupItem>
                  {index !== 0 && (
                    <SimpleItem
                      render={() => (
                        <Button
                          text={"Remove plot"}
                          onClick={() => handleRemovePlot(index)}
                          disabled={!isEditing}
                        />
                      )}
                    />
                  )}
                </GroupItem>
              </GroupItem>
            ))}
          </GroupItem>
          <SimpleItem
            render={() => (
              <Button
                text={"Add plot"}
                onClick={handleAddPlot}
                disabled={!isEditing}
              />
            )}
          />
        </GroupItem>
        <GroupItem cssClass="first-group" caption="Cattle details">
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="numberOfCattleHeads"
              render={() => (
                <div>
                  <NumberBox
                    id="numberOfCattleHeads"
                    min={0}
                    value={farmDataValues.numberOfCattleHeads}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        numberOfCattleHeads: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"farmDataGroup"}>
                      <PatternRule
                        pattern={/\b(?<!\.)\d+(?!\.)\b/}
                        message="Value should be integer number"
                      />
                    </Validator>
                  </NumberBox>
                  <Tooltip
                    target="#numberOfCattleHeads"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Number Of Cattle Heads</div>
                  </Tooltip>
                </div>
              )}
            />
            <SimpleItem
              dataField="averageWeeklyHoursSpentOnCattle"
              render={() => (
                <div>
                  <NumberBox
                    id="averageWeeklyHoursSpentOnCattle"
                    min={0}
                    value={farmDataValues.averageWeeklyHoursSpentOnCattle}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        averageWeeklyHoursSpentOnCattle: parseFloat(value),
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                  </NumberBox>
                  <Tooltip
                    target="#averageWeeklyHoursSpentOnCattle"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Average Weekly Hours Spent On Cattle</div>
                  </Tooltip>
                </div>
              )}
            />
          </GroupItem>
        </GroupItem>
        <GroupItem cssClass="first-group" caption="Fish details">
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="numberOfFishHeads"
              render={() => (
                <div>
                  <NumberBox
                    id="numberOfFishHeads"
                    min={0}
                    value={farmDataValues.numberOfFishHeads}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        numberOfFishHeads:  value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"farmDataGroup"}>
                      <PatternRule
                        pattern={/\b(?<!\.)\d+(?!\.)\b/}
                        message="Value should be integer number"
                      />
                    </Validator>
                  </NumberBox>
                  <Tooltip
                    target="#numberOfFishHeads"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Number Of Fish Heads</div>
                  </Tooltip>
                </div>
              )}
            />
            <SimpleItem
              dataField="averageWeeklyHoursSpentOnFish"
              render={() => (
                <div>
                  <NumberBox
                    id="averageWeeklyHoursSpentOnFish"
                    min={0}
                    value={farmDataValues.averageWeeklyHoursSpentOnFish}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        averageWeeklyHoursSpentOnFish: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                  </NumberBox>
                  <Tooltip
                    target="#averageWeeklyHoursSpentOnFish"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Average Weekly Hours Spent On Fish</div>
                  </Tooltip>
                </div>
              )}
            />
          </GroupItem>
        </GroupItem>
        <GroupItem cssClass="first-group" caption="Commerce details">
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="numberOfProducts"
              render={() => (
                <div>
                  <NumberBox
                    id="numberOfProducts"
                    min={0}
                    value={farmDataValues.numberOfProducts}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        numberOfProducts: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"farmDataGroup"}>
                      <PatternRule
                        pattern={/\b(?<!\.)\d+(?!\.)\b/}
                        message="Value should be integer number"
                      />
                    </Validator>
                  </NumberBox>
                  <Tooltip
                    target="#numberOfProducts"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Number Of Products</div>
                  </Tooltip>
                </div>
              )}
            />
            <SimpleItem
              dataField="averageWeeklyHoursSpentOnCommerce"
              render={() => (
                <div>
                  <NumberBox
                    id="averageWeeklyHoursSpentOnCommerce"
                    min={0}
                    value={farmDataValues.averageWeeklyHoursSpentOnCommerce}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        averageWeeklyHoursSpentOnCommerce: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                  </NumberBox>
                  <Tooltip
                    target="#averageWeeklyHoursSpentOnCommerce"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Average Weekly Hours Spent On Commerce</div>
                  </Tooltip>
                </div>
              )}
            />
          </GroupItem>
        </GroupItem>
        <GroupItem cssClass="first-group" caption="Health and Safety details">
          <GroupItem colCount={2}>
            <SimpleItem
              dataField="numberOfWorkAccidentsPerYear"
              render={() => (
                <div>
                  <NumberBox
                    id="numberOfWorkAccidentsPerYear"
                    min={0}
                    value={farmDataValues.numberOfWorkAccidentsPerYear}
                    onValueChange={(value) =>
                      setFarmDataValues({
                        ...farmDataValues,
                        numberOfWorkAccidentsPerYear: value,
                      })
                    }
                    stylingMode="outlined"
                    disabled={!isEditing}
                  >
                    <Validator validationGroup={"farmDataGroup"}>
                      <PatternRule
                        pattern={/\b(?<!\.)\d+(?!\.)\b/}
                        message="Value should be integer number"
                      />
                    </Validator>
                  </NumberBox>
                  <Tooltip
                    target="#numberOfWorkAccidentsPerYear"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    closeOnOutsideClick={false}
                  >
                    <div>Number Of Work Accidents Per Year</div>
                  </Tooltip>
                </div>
              )}
            />
            {/* <SimpleItem
              dataField="numberOfWorkDaysLostDueToWorkAccidentsPerYear"
              editorType="dxSelectBox"
              editorOptions={renderAccidentOptions}
            /> */}
          </GroupItem>
        </GroupItem>
      </Form>
      {isEditing && (
        <div className={"buttons"}>
          <Button
            text="Validate"
            validationGroup={"farmDataGroup"}
            onClick={validateGroup}
            loading={loading}
            useSubmitBehavior={true}
          />
          <Button text="Cancel" onClick={onSubmit} buttonStyle={"secondary"} />
        </div>
      )}
    </>
  );
};

export default FarmData;

FarmData.propTypes = {
  isEditing: PropTypes.bool,
  onSubmit: PropTypes.func,
  farmerId: PropTypes.string,
};
