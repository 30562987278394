import React, { useMemo } from 'react'
import { Button as DXButton } from 'devextreme-react/button';
import FileUploaderDX from 'devextreme-react/file-uploader';
import './fileUploader.scss'

const FileUploader = ({file, label, isEditable, acceptImg=true, acceptPdf=true, onFileChange}) => {

  const buttonText = useMemo(() => file ? `Change the ${label}` : `Upload a ${label}`,
    [file, label])

  const isPdf = useMemo(() => file && (file.startsWith('data:application/pdf') || file.substring(file.lastIndexOf('/'), file.lastIndexOf('?')).indexOf('.pdf') > 0),
    [file])

  const accept = useMemo(() => {
    const values = []
    acceptImg && values.push('image/*')
    acceptPdf && values.push('application/pdf')
    return values.join(',')
  }, [acceptImg, acceptPdf])

  return (
    <div className="file-uploader">
      <div className="note">
        Maximum file size: <span>5 MB</span>.
      </div>
      { isEditable &&
        <div className="selector">
          { file && file.length > 0 &&
            <DXButton className="delete-button" icon="clear" onClick={() => onFileChange(null)}/>
          }
          <FileUploaderDX
            selectButtonText={buttonText}
            labelText=""
            accept={accept}
            onValueChange={(files) => onFileChange(files[0])}
            uploadMode="useForm"
            maxFileSize={5000000}
            showFileList={false}
          />
        </div>
      }
      { file &&
        (isPdf
          ? <embed src={file} height="400" width="100%"/>
          : <img src={file} alt={label} className="file"/>
        )
      }
    </div>
  );
}

export default FileUploader;

