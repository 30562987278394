import React, { 
  useState, 
  useCallback, 
  useEffect, 
  useRef
} from "react"
import { useLogin } from "../../cookies"
import { useProgram } from '../../programs'
import { 
  useHistory, 
  useLocation
} from "react-router"
import {
  fetchPrices,
  upsertPrice,
  deletePrice
} from "../../api/prices"
import { fetchProductByPointOfSale } from "../../api/products"
import { 
  Spinner, 
  FormComponent, 
  Button 
} from "../../components"
import PricesIcon from "../../resources/icons/prices.png"
import "./prices.scss"
import { confirm } from "devextreme/ui/dialog"
import notify from "devextreme/ui/notify"

const Prices = () => {
  const { token } = useLogin()
  const program = useProgram()
  const history = useHistory()

  const { pathname, search } = useLocation()
  const splittedPath = pathname.split('/')
  const useQuery = () => new URLSearchParams(search)
  const query = useQuery()
  
  const pointOfSaleId = history.location.state?.id ?? splittedPath[splittedPath.length - 1]
  const pointOfSaleName = history.location.state?.name ?? query.get('name')
  
  const [loading, setLoading] = useState(true)

  const [products, setProducts] = useState([])
  const [prices, setPrices] = useState([])
  const [currentProductPrices, setCurrentProductPrices] = useState([])
  
  const formsRefs = useRef([])

  const fetchPointOfSaleProducts = useCallback(async () =>
    await fetchProductByPointOfSale(token, pointOfSaleId)
  , [token, pointOfSaleId])

  const fetchProductPrices = useCallback(async (productId) => {
    const pricesByProduct = await fetchPrices(token, pointOfSaleId, productId)
    return pricesByProduct.data
  }, [token, pointOfSaleId])

  const fetchProductsAndPrices = useCallback(async () => {
    const response = await fetchPointOfSaleProducts(token, pointOfSaleId)
    if (!response.isOk) {
      notify(response.message, 'error', 2000)
      setLoading(false)
    } else {
      setProducts(response.data)
      const pricesList = await Promise.all(response.data.map(product => fetchProductPrices(product.id))),
            flattenPricesList = pricesList.flat(1)
      
      if(!!flattenPricesList.length)
        setPrices(flattenPricesList)
       else
        setPrices([])
        
      setLoading(false)
    } 
  }, [fetchPointOfSaleProducts, token, pointOfSaleId, fetchProductPrices])
  
  useEffect(() => {
      fetchProductsAndPrices()
  }, [fetchProductsAndPrices])

  useEffect(() => {
    if(!!prices.length) {
      const date = new Date(new Date().setHours(0, 0, 0, 0))
      
      const currentPrices = prices.filter(price => 
        (!price.startDate && date <= new Date(price.endDate).setHours(0, 0, 0, 0)) 
        || (date >= new Date(price.startDate).setHours(0, 0, 0, 0) && !price.endDate)
        ||  (!price.startDate && !price.endDate)
        ||  (date >= new Date(price.startDate).setHours(0, 0, 0, 0) && date <= new Date(price.endDate).setHours(0, 0, 0, 0))
      )

      const uniqueCurrentPrices = currentPrices.filter((currentPrice, index) => 
          currentPrices.findIndex((price) => price.product === currentPrice.product) === index
      )
      setCurrentProductPrices(uniqueCurrentPrices)
    } 
  }, [prices])

  const handleAddPrices = async () => {
    const resps = await Promise.all(formsRefs.current.map(ref => 
        upsertPrice(token, pointOfSaleId, ref.props.formData.product, ref.props.formData)
    ))
    
    fetchProductsAndPrices(pointOfSaleId)

    if(resps.every(resp => resp.isOk)) {
      return notify(
        'Update done',
        'success',
        2000
      )
    } else {
      return notify('Update failed, please try again', 'error', 2000)
    }
  }

  const handleCancelModifications = () =>
    fetchProductsAndPrices(pointOfSaleId)

  const handleDelete = async (priceToDelete) => {
    setLoading(true)
    formsRefs.current = []
    if(priceToDelete.id) {
      const res = await deletePrice(token, pointOfSaleId, priceToDelete.product, priceToDelete.id)
    
      if (res.isOk) {
        setPrices(currentPrices => currentPrices.filter(price => price.id !== priceToDelete.id))
        notify(
          `Price ${priceToDelete.id} was successfully deleted`,
          'success',
          2000
        )
      } else {
        notify(`Failed to delete price ${priceToDelete.id}`, 'error', 2000)
      }
    } else {
      setPrices(currentPrices => currentPrices.filter(price => price !== priceToDelete))
      notify(
        `Price deleted`,
        'success',
        2000
      )
    }
    setLoading(false)
  }

  const onDelete = (priceToDelete) => {
     let result = confirm(
      "Are you sure you want to delete selected price ?",
      "Delete"
    )

    result.then((dialogResult) => {
      dialogResult && handleDelete(priceToDelete)
    })
  }
  
  const onAddForm = (product) => {
    const newEmptyPrice = {
      program: product.program,
      pointOfSale: pointOfSaleId,
      product: product.id,
      value: 0, 
      currency: program.configs.priceLocale,
      dateStart: null, 
      dateEnd: null
    }
    setPrices(currentPrices => [...currentPrices, newEmptyPrice])
  }
  
  return (
    !loading ?
    <>
      <div>
        <div className={"contentBlock"}>
          <img src={PricesIcon} alt="PricesIcon" />
          <h2>Products for { pointOfSaleName }</h2>
        </div>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
      
            { history.location.state?.id && 
              <div className={"contentBlock"}>
                <Button
                  className={"back-btn"}
                  text="< Back"
                  onClick={() => history.goBack()}
                  disabled={false}
                />
              </div>
            }

            { products.length ? 
              <div>
                { products.map((product) => {
                  return(
                    <div className="product-container" key={product.id}>
                      <div className="product-id"> { product.name }</div> 
                        { prices.length ?
                          <div>
                            <div>
                              
                              { currentProductPrices.some(price => price.product === product.id) ?

                                currentProductPrices.map((currentPrice, currentPriceIndex) => {
                                  return(
                                    currentPrice.product === product.id && 

                                    <div className="current-price" key={currentPriceIndex}>
                                      <span>current price</span>
                                      <span className="bold value">{ currentPrice.value } { currentPrice.currency }</span>
                                    </div>
                                    
                                  )
                                })
                                :
                                <div>No price set for today</div>
                              }
                              
                            </div>
                            <div>
                              { prices.filter(p => p.product === product.id).map((price, priceIndex) => {                       
                                return(
                                  <div key={priceIndex}>
                                    <div> 
                                      
                                      <div className="form-container">
                                        <div className="product-prices">

                                          <div className="product-prices-form" key={priceIndex}>
                                            <div className="form">
                                              <Button
                                                text="X"
                                                onClick={() => onDelete(price)}
                                                disabled={false}
                                              />
                                              <FormComponent
                                                loading={loading}
                                                formData={price}
                                                ref={(el) => { if(el && !formsRefs.current.includes(el)) formsRefs.current[formsRefs.current.length] = el }}
                                              />
                                            </div> 
                                          </div>

                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>

                          :

                          <div className={"content-block"}>
                          </div>
                        }

                        <div className="add-btn">
                        <Button
                          text="+ Add new price"
                          onClick={() => onAddForm(product)}
                          disabled={false}
                        />
                      </div>
                    </div>
                  )
                })}

                <div className="handle-btns">
                  <Button
                    text="Reset"
                    buttonStyle={"secondary"}
                    onClick={handleCancelModifications}
                    disabled={false}
                  />
                  <Button
                    text="Submit"
                    onClick={handleAddPrices}
                    disabled={false}
                  />
                </div>
              </div>
              
              :
                            
              <div className={"contentBlock"}>
                <div>No products.</div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
    
     :
     <div className="product-container">
        <div className={"content-block"}>
          <Spinner/>
        </div>
     </div>
  )
}

export default Prices