import React, { useCallback, useEffect, useMemo, useRef } from "react";
import TreeView from "devextreme-react/tree-view";
import * as events from "devextreme/events";
import PropTypes from "prop-types";
import { useLogin } from "../../cookies";
import { getNavByUserRoles } from "../../app-navigation";
import { useNavigation } from "../../contexts/navigation";
import { useScreenSize } from "../../utils/media-query";
import { useProgram } from '../../programs'
import "./side-navigation-menu.scss";

const SideNavigationMenu = (props) => {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady,
  } = props;
  const program = useProgram()
  const { user } = useLogin();

  const { isLarge, isXLarge } = useScreenSize();
  const normalizePath = useCallback(() => {
    const userRolesFilteredNav = getNavByUserRoles(user.userRoles, program.configs?.dashboards);

    return userRolesFilteredNav.map((item) => ({
      ...item,
      path: item.path(program.name),
      expanded: isLarge || isXLarge }));
  }, [program, user, isLarge, isXLarge]);

  const items = useMemo(() => normalizePath(), [normalizePath]);

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    (element) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, "dxclick");
      }

      wrapperRef.current = element;
      events.on(element, "dxclick", (e) => {
        openMenu(e);
      });
    },
    [openMenu]
  );

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  return (
    <div
      className={"dx-swatch-additional side-navigation-menu"}
      ref={getWrapperRef}
    >
      {children}
      <div className={"menu-container"}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={"path"}
          selectionMode={"single"}
          focusStateEnabled={false}
          expandEvent={"click"}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={"100%"}
        />
      </div>
    </div>
  );
};

export default SideNavigationMenu;

SideNavigationMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  selectedItemChanged: PropTypes.func,
  openMenu: PropTypes.func,
  compactMode: PropTypes.bool,
  onMenuReady: PropTypes.func,
};
